import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// vendor
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
// app
import FloatArrows from './FloatArrows';
import { updatePlan } from '../HomeSlice';


import BackgroundSection from '../../../assets/images/header-doc.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundSection})`
  }
}));

function Pricing(props) {
  // const homeState = useSelector(state => state.home);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let plansRender = [];
  let plans = [
    // {
    //   title: '@pricing.individual.title',
    //   amount: '@pricing.individual.amount',
    //   currency: '@pricing.individual.currency',
    //   period: '@pricing.individual.period',
    //   rangeUsers: '@pricing.individual.rangeUsers',
    //   features: [
    //     '@pricing.features.treatments',
    //     '@pricing.features.10storage',
    //     '@pricing.features.customizedClinic',
    //     '@pricing.features.allBenefits'
    //   ]
    // },
    {
      title: '@pricing.basic.title',
      amount: '@pricing.basic.amount',
      currency: '@pricing.basic.currency',
      period: '@pricing.basic.period',
      rangeUsers: '@pricing.basic.rangeUsers',
      features: [
        '@pricing.features.treatments',
        '@pricing.features.20storage',
        '@pricing.features.customizedClinic',
        '@pricing.features.allBenefits'
      ]
    },
    {
      title: '@pricing.intermediate.title',
      amount: '@pricing.intermediate.amount',
      currency: '@pricing.intermediate.currency',
      period: '@pricing.intermediate.period',
      rangeUsers: '@pricing.intermediate.rangeUsers',
      features: [
        '@pricing.features.forms',
        '@pricing.features.treatments',
        '@pricing.features.40storage',
        '@pricing.features.customizedClinic',
        '@pricing.features.allBenefits'
      ]
    },
    {
      title: '@pricing.advanced.title',
      amount: '@pricing.advanced.amount',
      currency: '@pricing.advanced.currency',
      period: '@pricing.advanced.period',
      rangeUsers: '@pricing.advanced.rangeUsers',
      features: [
        '@pricing.features.forms',
        '@pricing.features.treatments',
        '@pricing.features.60storage',
        '@pricing.features.customizedClinic',
        '@pricing.features.allBenefits'
      ]
    }
  ]

  for (let i = 0; i < plans.length; i++) {
    let plan = plans[i];
    let features = [];

    for (let j = 0; j < plan.features.length; j++) {
      let feature = plan.features[j];
      features.push(
        <div key={'feature-'+j+'-'+feature}>{t(feature)}</div>
      )
    }

    plansRender.push(
      <div key={'plan-'+i} className="plan-container">
        <span className="title">{t(plan.title)}</span>
        <span className="princing">
          <span className="amount-container">
            <span className="currency">{t(plan.currency)}</span>
            <span className="amount">{t(plan.amount)}</span>
          </span>/<span className="period">{t(plan.period)}</span>
        </span>
        <span className="range-users">{t(plan.rangeUsers)}</span>
        <div className="plan-features">
          {features}
        </div>
        <div className="start-plan-button-container">
          <Button 
            variant="contained"
            color="primary"
            onClick={(e) => {
              dispatch(updatePlan(plan));
            }}>
            <a
              className="no-a-styles"
              href="#contact-us">{t('@home.start')}</a>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <section
      id={props.selfId}
      className={classes.root + " section-container " + props.selfId}>
      <div className="container">
        <div className="row">
          <div className="col-md-11 offset-md-1 align-items-center">
            <h3 className="mb-0">{t('@home.pricing')}</h3>
            <h4 className="section-legend">{t('@home.printingLegend')}</h4>
          </div>
        </div> 
        <div className="row">
          <div className="plans-container">
            {plansRender}
          </div>
        </div>
      </div>
      <FloatArrows
        upSection={props.upSection}
        bottomSection={props.bottomSection}/>
    </section>
  )
}

export default Pricing;